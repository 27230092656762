import React, { useEffect, useState } from 'react';
import './AddEtkinlik.css';
import Navbar from '../Navbar/Navbar';
import photoImg from '../../Assets/photoImg.svg';
import arrowImg from '../../Assets/arrowleft.png';
import { useLocation } from 'react-router-dom';
import { getFirestore, setDoc, doc, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

function AddEtkinlik({UserID}) {
  const location = useLocation();
  const db = getFirestore();
  const storage = getStorage();
  const [userID, setUserID] = useState();
  const [etkinlikID, setEtkinlikID] = useState(uuidv4()); 
  const [eventName, setEventName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [category, setCategory] = useState('');
  const [showPopupOrganization, setShowPopupOrganization] = useState(false);
  const [popupMessageOrganization, setPopupMessageOrganization] = useState('');
  const [fee, setFee] = useState('');
  const [rules, setRules] = useState('');
  const [description, setDescription] = useState('');
  const [ticketsSold, setTicketsSold] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [organizatorList, setOrganizatorList] = useState([]); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setUserID(urlParams.get("UserID"));
    setEventName(urlParams.get("etkinlikTitle"));
    setStartTime(urlParams.get("etkinlikStartTime"));
    setStartDate(urlParams.get("etkinlikStartDate"));
    setRules(urlParams.get("etkinlikRules"));
    setDescription(urlParams.get("etkinlikExplanation"));
    setTicketsSold(urlParams.get("etkinlikQuota"));
  }, [location.search]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };


  const handleSubmit = async () => {
    if (!eventName || !startDate || !startTime || !category || !fee || !rules || !description || !ticketsSold || !image) {
      setPopupMessageOrganization('Lütfen tüm bilgileri doldurun.');
      setShowPopupOrganization(true);
      setTimeout(() => setShowPopupOrganization(false), 3000);
      return;
    }

    try {
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `event-images/${etkinlikID}_${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef); 
      }

     
        const getOrganizatorList = async () => {
          try {
            const organizerListRef = collection(db, "organizer"); 
            const snapshots = await getDocs(organizerListRef); 
    
            const docs = snapshots.docs.map((doc) => {
              const data = doc.data();
              data.id = doc.id;
              return data;
            });
    
            setOrganizatorList(docs); 
            setLoading(false);
          } catch (error) {
            console.error("Error fetching organizator data: ", error);
            setLoading(false); 
          }
        };
    
        getOrganizatorList(); 
    
    
      const filteredOrganizer = organizatorList.find(organizer => organizer.id === userID);
      console.log(userID + "userID")

      console.log(filteredOrganizer.name)
  

      await setDoc(doc(db, 'organization', etkinlikID), {
        title: eventName,
        searchText: eventName.toLowerCase(),
        startDate: startDate,
        startTime: startTime,
        price: fee,
        rules: rules,
        explanation: description,
        quota: ticketsSold,
        imageURL: imageUrl,
        organizerName:filteredOrganizer.name,
        organizerImageURL:filteredOrganizer.imageURL,
        organizerID: userID || 'Unknown User',
        id: etkinlikID,
        category: category,
        isConfirm:false,
      });

      setPopupMessageOrganization('Etkinlik başarıyla eklendi!');
      setTimeout(() => {
        window.location.href = `/?UserID=${userID}`
      
      }, 2000);
    } catch (error) {
      console.error('Error adding document: ', error);
      setPopupMessageOrganization('Etkinlik eklenemedi, lütfen tekrar deneyin.');
    }
    setShowPopupOrganization(true);
    setTimeout(() => setShowPopupOrganization(false), 2000);
  };


 
  return (
    <div>
      <Navbar UserID={userID} navbarImg2={arrowImg} navbarName={"sinartmedia"} />
      <div className='AddEtkinlikMain'>
        <h1>Yeni Etkinlik Ekle</h1>
        <div className='AddEtkinlikContentDiv'>
          {previewImage ? (
            <img src={previewImage} alt="Etkinlik Kapak Resmi" />
          ) : (
            <img src={photoImg} alt="Upload" />
          )}
          <p className='kapakP'>Kapak Resmi Yükle</p>
          <input className='addetkinlikImgInput' type='file' onChange={handleImageUpload} />
          <div className='inputGroup'>
            <div className='inputPair'>
              <label>Etkinlik Adı:</label>
              <input type='text' placeholder='Etkinlik Adı' value={eventName} onChange={(e) => setEventName(e.target.value)} />
            </div>
            <div className='inputPair'>
              <label>Etkinlik Başlangıç Tarihi:</label>
              <input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div className='inputPair'>
              <label>Etkinlik Başlangıç Saati:</label>
              <input type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </div>
            <div className='inputPair'>
              <label>Ücret:</label>
              <input type='number' placeholder='Ücret' value={fee} onChange={(e) => setFee(e.target.value)} />
            </div>
            <div className='inputPair'>
              <label>Etkinlik Kuralları:</label>
              <textarea placeholder='Etkinlik Kuralları' value={rules} onChange={(e) => setRules(e.target.value)}></textarea>
            </div>
            <div className='inputPair'>
              <label>Kategori:</label>
              <select value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="a">Kategori Seçiniz</option>

                <option value="sinema">Sinema</option>
                <option value="konser">Konser</option>
                <option value="tiyatro">Tiyatro</option>
                <option value="festival">Festival</option>
                <option value="stand-up">Stand-Up</option>
              </select>
            </div>
            <div className='inputPair'>
              <label>Etkinlik Açıklaması:</label>
              <textarea placeholder='Etkinlik Açıklaması' value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
            <div className='inputPair'>
              <label>Kontenjan:</label>
              <input type='number' placeholder='Kontenjan' value={ticketsSold} onChange={(e) => setTicketsSold(e.target.value)} />
            </div>
          </div>
          <div className='buttons'>
            <button className='submitButton' onClick={handleSubmit}>Etkinliği Onaya Gönder</button>
          </div>
        </div>
      </div>
      {showPopupOrganization && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessageOrganization}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddEtkinlik;
